<template>
  <div class="p-1">
    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Checklist</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group label="Title" invalid-feedback="Title is required.">
              <b-form-input
                ref="title"
                v-model="checkObj.title"
                placeholder="Enter title"
                @focusout="checkTitle()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Save()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="allChecklist"
          :fields="fields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editCL(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteCL(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <!-- <b-row>
            <b-col
              style="border-bottom: 2px solid lightgray"
              v-if="accTypes.length > 0"
              class="d-flex align-items-center justify-content-between mb-1 pb-1 pt-1"
              md="12"
              v-for="acc in accTypes"
              :key="acc.id"
            >
              <h6 class="mr-1">{{ acc.account }}</h6>
              <div>
                <b-button
                  variant="primary"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="editAcc(acc)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="deleteAcc(acc)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </b-col>
            <b-col md="12" v-else class="text-center">
              <h4>No Records Found</h4>
            </b-col>
          </b-row> -->
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-row align-h="center">
      <b-col class="text-center mb-1" lg="9" md="11">
        <b-img
          ref="previewEl"
          :src="school.logo"
          style="width: 100%; max-width: 150px"
        />
        <div class="mt-50">
          <h3>{{ school.name }}</h3>
          <h5>
            <feather-icon icon="MapPinIcon" class="mr-50" />
            {{ school.address }}
          </h5>
          <h5>
            <feather-icon icon="PhoneIcon" class="mr-50" />{{ school.phone }}
          </h5>
        </div>
      </b-col>
      <b-col v-if="submitted" class="text-center" lg="9" md="11">
        <div style="height: 280px">
          <lottie-animation path="submitted.json" />
        </div>
        <b-button variant="primary" @click="cleanStates()">
          <span>Add More</span>
        </b-button>
      </b-col>
      <b-col v-else lg="10" md="11">
        <!-- <h3 class="mb-1">Profile Information</h3> -->
        <b-card class="">
          <h4 class="text-uppercase mb-0">Inquiry Form</h4>
          <hr />
          <b-row>
            <b-col xl="4" lg="5" md="6">
              <b-form-group
                label="Applying for Campus *"
                invalid-feedback="Campus is required."
                label-class="font-weight-bold"
                ref="camp"
              >
                <v-select
                  v-model="currentCampus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :disabled="disCamp"
                  :options="campuses"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="branch"
                  placeholder="Select campus"
                  @input="setData()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Name *"
                invalid-feedback="Name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  id="mc-first-name"
                  placeholder="Enter student name"
                  ref="name"
                  v-model.trim="myObj.name"
                  @focusout="CheckName()"
                />
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Father name *"
                invalid-feedback="Father name is required."
                label-class="font-weight-bold"
                ref="fname"
              >
                <b-form-input
                  id="mc-first-name"
                  placeholder="Enter student father name"
                  v-model.trim="myObj.father_name"
                  @focusout="CheckFName()"
                />
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <!-- <b-form-group
                v-if="myObj.inquiryType == 'visitor'"
                label="CNIC"
                invalid-feedback="CNIC is required."
                ref="cnic"
              >
                <b-form-input
                  ref="cnic"
                  placeholder="Enter visitor's cnic"
                  v-model="myObj.cnic"
                />
              </b-form-group> -->
              <b-form-group
                label="Class *"
                invalid-feedback="Class is required."
                label-class="font-weight-bold"
                ref="class"
              >
                <v-select
                  v-model="myObj.cls"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="classes"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="name"
                  :disabled="dataLoading"
                  placeholder="Select class"
                  @input="checkClass()"
                />
              </b-form-group>
            </b-col>

            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Age"
                invalid-feedback="Age is required."
                ref="age"
              >
                <b-form-input placeholder="Enter age" v-model="myObj.age" />
              </b-form-group>
            </b-col>

            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Contact number *"
                invalid-feedback="Please enter correct phone number."
                label-class="font-weight-bold"
              >
                <vue-tel-input
                  v-model="myObj.contact"
                  :dropdownOptions="dropOptions"
                  @input="CheckMobile"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="contacterror"
                  >Contact number is required.</small
                >
              </b-form-group>
            </b-col>

            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Email"
                invalid-feedback="Email address is required."
              >
                <b-form-input
                  ref="email"
                  type="email"
                  id="mc-email"
                  placeholder="myskool@example.com"
                  v-model="myObj.email"
                />
                <!-- @focusout="CheckEmail()" -->
              </b-form-group>
            </b-col>
            <!-- <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Date"
                invalid-feedback="Date is required."
                ref="date"
              >
                <flat-pickr
                  ref="date"
                  v-model="myObj.date"
                  :config="config"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>

            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Purpose "
                invalid-feedback="Purpose is required."
              >
                <b-form-input
                  ref="purpose"
                  type="text"
                  id="mc-email"
                  placeholder="Enter purpose here."
                  v-model="myObj.purpose"
                />
              </b-form-group>
            </b-col>

            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group label="Tag" invalid-feedback="Tag is required.">
                <b-form-input
                  ref="tag"
                  placeholder="Enter tag here."
                  v-model="myObj.tag"
                />
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="Next follow up date"
                invalid-feedback="Next follow up date is required."
                ref="next_date"
              >
                <flat-pickr
                  ref="next_date"
                  v-model="myObj.nextFollowup"
                  :config="config"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col> -->

            <b-col xl="6" lg="6" md="12" sm="12" cols="12">
              <b-form-group
                label="Last School *"
                invalid-feedback="Last School is required."
                label-class="font-weight-bold"
                ref="last_school"
              >
                <b-form-input
                  placeholder="Enter last school name"
                  v-model.trim="myObj.lastSchool"
                  @focusout="checkLast()"
                />
              </b-form-group>
            </b-col>

            <b-col xl="3" lg="3" md="4" sm="12" cols="12">
              <b-form-group
                label="How did you hear about us?"
                invalid-feedback="Reference is required."
                ref="refer"
              >
                <v-select
                  v-model="myObj.referenceID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="referenceData"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="name"
                  placeholder="Select reference"
                />
                <!-- @input="checkRef()" -->
              </b-form-group>
            </b-col>
            <b-col xl="9" lg="9" md="8" cols="12">
              <b-form-group
                label="Address"
                invalid-feedback="Address is required."
                ref="area"
              >
                <b-form-input
                  placeholder="Enter residential area."
                  v-model="myObj.residentialArea"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col xl="6" lg="6" md="6" sm="12" cols="12">
              <b-form-group
                label="Note"
                invalid-feedback="Note is required."
                ref="inq_note"
              >
                <b-form-input placeholder="Enter Note." v-model="myObj.note" />
              </b-form-group>
            </b-col> -->

            <b-col xl="12" cols="12" v-if="false">
              <b-form-group
                :state="nameState"
                label="Upload Documents"
                invalid-feedback="Documents are required"
                required
                ref="docs"
                class=""
              >
                <div class="d-flex flex-wrap">
                  <!-- <b-button
                  ref="docs"
                  @click="$refs.psamplefile.click()"
                  variant="primary"
                  :disabled="request || mydocloading"
                  block
                >
                  <b-spinner
                    v-if="mydocloading"
                    type="grow"
                    small
                    label="Loading..."
                  />
                  <span v-else>Upload</span>
                </b-button> -->
                  <div
                    class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
                    style="width: 110px; height: 96px"
                    @click="$refs.psamplefile.click()"
                  >
                    <b-spinner
                      v-if="mydocloading"
                      small
                      label="Loading..."
                      variant="primary"
                    />
                    <feather-icon
                      v-else
                      icon="PlusIcon"
                      size="34"
                      class="text-primary"
                    />
                  </div>
                  <div
                    class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
                    style="width: 110px; height: 96px; position: relative"
                    v-for="(file, index) in attachList"
                    :key="index"
                    @click="openFile(file)"
                  >
                    <b-avatar
                      size="20"
                      variant="primary"
                      style="position: absolute; top: -5px; right: -5px"
                    >
                      <feather-icon
                        icon="XIcon"
                        @click.stop="deleteDocument(index)"
                      />
                    </b-avatar>
                    <feather-icon
                      :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
                      size="30"
                      class="text-primary"
                    />
                  </div>

                  <input
                    type="file"
                    id="psamplefile"
                    hidden
                    ref="psamplefile"
                    multiple
                    @change="PsampleAdd()"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-badge
                style="margin-bottom: 5px"
                class="p-1"
                v-for="(file, index) in attachList"
                :key="index"
                variant="light-primary"
              >
                <a :href="file" target="_blank" class="text-primary">
                  {{ index + 1 + " : " + file.slice(0, 40) }}
                </a>
    
                <i
                  class="text-danger fa fa-trash"
                  style="cursor: pointer"
                  @click="deleteDocument(index)"
                ></i>
              </b-badge>
              
            </b-col> -->
          </b-row>
          <!-- 
          <b-row align-h="end">
            
           
          </b-row> -->
        </b-card>
      </b-col>
    </b-row>

    <b-card v-if="false">
      <b-row class="p-50">
        <b-col xl="11" lg="10" md="9">
          <b-row>
            <b-col
              xl="2"
              lg="3"
              md="4"
              class="p-0"
              v-for="li in formattedCL"
              :key="li"
            >
              <h5>{{ li.title }}</h5>
              <div class="d-flex align-items-center pr-50" style="width: 100%">
                <b-button
                  :variant="li.checkListData ? 'primary' : 'outline-primary'"
                  class="btn-icon rounded-circle mr-50"
                  @click="li.checkListData = !li.checkListData"
                >
                  <feather-icon icon="CheckIcon" size="16" />
                </b-button>

                <b-progress
                  style="width: 100%"
                  class="progress-bar-dark"
                  height="20px"
                  :value="li.checkListData ? '100' : '0'"
                  variant="success"
                ></b-progress>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="1" lg="2" md="3" class="mt-50 p-0">
          <!-- <b-button @click="AddOpen()" variant="primary" class="btn-icon" block>
            <feather-icon icon="PlusIcon" class="" />
            <span style="font-size: 80%">Checklist</span>
          </b-button> -->
          <b-badge
            @click="AddOpen()"
            variant="primary"
            class="cursor-pointer"
            style="float: right"
          >
            <feather-icon icon="PlusIcon" class="" />
            Checklist
          </b-badge>
        </b-col>
      </b-row>
    </b-card>

    <b-col
      md="12"
      class="d-flex align-items-center justify-content-between"
      v-if="false"
    >
      <h3 class="mt-1">Follow up</h3>
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="bv-no-focus-ring col-form-label mr-1">Notifications:</h4>
        <b-form-checkbox v-model="notification" switch @input="setNoti()" />
      </div>
    </b-col>
    <b-card class="mt-1" v-if="false">
      <!-- <b-row>   
        <b-col
          xl="4"
          lg="4"
          md="6"
          sm="8"
          class="d-flex justify-content-between"
        >        
          <b-form-group
            label="Next follow up date"
            invalid-feedback="Next follow up date is required."
            ref="next_date"
          >
            <flat-pickr
              ref="next_date"
              v-model="myObj.nextFollowup"
              :config="config"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>    
      </b-row>
      <hr /> -->
      <b-row
        v-for="(item, ind) in followObj"
        :key="ind"
        style="display: flex; align-items: center"
      >
        <b-col md="2" class="">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              ref="date"
              :config="config"
              v-model="item.date"
              class="form-control"
              placeholder="Select Date."
            />
            <!-- @on-change="CheckDate()" -->
          </b-form-group>
        </b-col>
        <b-col md="7" class="">
          <b-form-group
            label="Note"
            invalid-feedback="Note is required."
            ref="response"
          >
            <b-form-input
              id="mc-first-name"
              ref="response"
              placeholder="Enter note here"
              v-model="item.note"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" class="">
          <b-form-group
            label="By"
            invalid-feedback="User is required."
            ref="user"
          >
            <b-form-input
              ref="user"
              placeholder="Select user"
              v-model="item.followupBy"
            />
            <!-- <v-select
              v-model="item.followupBy"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allUsers"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              ref="user"
              placeholder="Select user"
            /> -->
          </b-form-group>
        </b-col>
        <!-- <b-col md="2" class="">
          <b-form-group
            label="Next Date"
            invalid-feedback="Next Date is required."
            ref="next_date"
          >
            <flat-pickr
              ref="next_date"
              :config="config"
              v-model="item.nextDate"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col> -->
        <b-col md="1">
          <b-button
            v-if="ind == followObj.length - 1"
            @click="AddDesc()"
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon size="16" icon="PlusIcon" />
          </b-button>
          <b-button
            v-if="followObj.length > 1"
            @click="removeDesc(item, ind)"
            variant="outline-danger"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="16" icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-row v-if="!submitted" align-h="center">
      <b-col md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          @click="Add()"
          :disabled="request || saveDisable"
          block
        >
          <b-spinner v-if="request" small type="grow" />
          <span v-else> Save </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";
import { TheMask } from "vue-the-mask";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

import { mapActions, mapGetters } from "vuex";

import * as XLSX from "xlsx";

export default {
  components: {
    LottieAnimation,
    //Multiselect,
    TheMask,
    BProgress,
    BInputGroup,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    //BAvatar,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormCheckbox,
    BForm,
    BContainer,
    //BCardTitle,
    //BCardText,
    //BMediaAside,
    BImg,
    // BMedia,
    BAvatar,
    BLink,
    // BSpinner,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  // mounted() {
  //   window.addEventListener("keydown", this.handleEvent);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("keydown", this.handleEvent);
  // },
  async created() {
    // console.log(this.$route.query);
    if (this.$route.query.cID) {
      let x = parseInt(this.$route.query.cID);
      if (!isNaN(x) && x > 0) {
        this.cID = x;
        this.disCamp = true;
        // console.log(this.cID);
      }
    }
    this.myObj.campusID = this.cID;
    setTimeout(() => {
      this.contacterror = false;
    }, 300);
    this.LoadCampuses();
    // this.LoadClasses();
    // this.loadChecklist(0);
    this.loadRefs();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.purpose.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      submitted: false,
      disCamp: false,
      campuses: [],
      currentCampus: 0,
      allUsers: [],
      currentInq: null,

      followObj: [],
      formattedCL: [
        // {
        //   id: 0,
        //   inquiryID: this.currentInq,
        //   campusID: this.$store.state.userData.cId,
        //   checklistID: 0,
        //   checkListData: false,
        // },
      ],
      visibility2: false,
      currentID: null,
      rights: {},
      classes: [],
      filterTags: [],
      filterClasses: [],
      filter: {
        clsIDs: [],
        tags: [],
        campusID: this.$store.state.userData.cId,
        db: this.$store.state.userData.db,
      },
      byFilter: false,
      filterLoading: false,
      contacterror: false,
      mydocloading: false,
      saveDisable: false,
      docsloading: "Upload",
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      departments: [],
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: null,

      errors: {
        status: false,
      },
      statusOptions: ["In stock", "Out stock"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        { label: "title", key: "title" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      school: {
        logo: "https://cdn.cloudious.net/file-1715961770731-956726559.png",
        name: "Happy Palace Grammar School",
        address:
          "C- 266, 267, 268, Block 10, Happy Chowrangi, FB Area, Karachi, Pakistan",
        phone: "111 474 722",
      },
      db: "/r6Q0RYpjkcmj801xyc19w==",
      cID: 1,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiYTdjYzU5YTEtZTRhOC00MmM1LWEzMmYtY2EzODM4ZTc0MDU4IiwiZXhwIjoyMDMwNjkyODMyLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0.xuRNNjiKeO7158I-_Yni-rQUcl__LK6_oOtOwo9XfGs",
      myObj: {
        id: 0,
        name: "",
        father_name: "",
        age: "",
        contact: "",
        email: "",
        address: "",
        purpose: "",
        tag: "",
        date: new Date().toJSON(),
        attachments: "",
        campusID: 1,
        cls: 0,
        cnic: "",
        lastSchool: "",
        nextFollowup: new Date().toJSON(),
        inquiryType: "inquiry",
        referenceID: 0,
        inquiryStatus: "pending",
        residentialArea: "",
      },
      referenceData: [],
      notification: this.$store.state.inqNotification,
      attachList: [],
      checkObj: {
        id: 0,
        title: "",
        details: "",
        campusID: this.$store.state.userData.cId,
      },
      accIcon: "",
      allChecklist: [],
      saving: false,
      detailObj: {},
      avatarURL:
        "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/female/110.png",
      searchDate: "",
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },

      uploadFields: {
        Name: "name",
        Father: "father_name",
        Contact: "contact",
        Email: "email",
        Purpose: "purpose",
        Tag: "tag",
        Date: "date",
      },
      exporting: false,
      clikced: 1,
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    handleEvent(event) {
      if (event.ctrlKey && event.keyCode === 83) {
        // console.log(event);
        event.preventDefault();
        if (this.clikced == 1) {
          this.clikced = 0;
          this.Add();
        }
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg|mp4)$/.test(url);
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    AddDesc() {
      this.followObj.push({
        id: 0,
        inquiryID: this.currentInq,
        campusID: this.$store.state.userData.cId,
        date: new Date().toJSON(),
        followupBy: "",
        note: "",
      });
      // console.log(this.followObj);
    },
    async removeDesc(item, ind) {
      if (item.id == 0) {
        this.followObj.splice(ind, 1);
        // console.log(this.followObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "InquiryFollowups/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.token,
        });
        if (status) this.followObj.splice(ind, 1);
        // console.log(this.followObj);
      }
    },
    CheckValues() {
      let state = true;
      this.followObj.forEach((el) => {
        if (el.date == "" || el.note.trim() == "" || el.followupBy == "") {
          state = false;
        }
      });
      return state;
    },
    async Save() {
      // console.log("obj:", this.checkObj);
      if (this.checkTitle() == true) {
        this.saving = true;

        if (this.checkObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "InquiryChecklist?db=" +
              this.$store.state.userData.db,
            body: this.checkObj,
            message: "Checklist added successfully.",
            context: this,
            token: this.token,
          });
          this.saving = false;
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "InquiryChecklist/" +
              this.checkObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Checklist updated successfully.",
            context: this,
            body: this.checkObj,
            token: this.token,
          });
          this.saving = false;
        }
        if (status) {
          this.loadChecklist(1);
          var elem = this.$refs["title"];
          elem.state = undefined;
          this.checkObj = {
            id: 0,
            title: "",
            details: "",
            campusID: this.$store.state.userData.cId,
          };
          this.accIcon = "PlusIcon";
        }
      }
    },
    setNoti() {
      this.$store.commit("setInquiry", this.notification);
    },
    deleteDocument(index) {
      this.attachList.splice(index, 1);
      // console.log("list", this.attachList);
      this.myObj.attachments = this.attachList.join(",");
    },
    PsampleAdd() {
      // console.log(this.$refs.psamplefile.files);

      if (this.$refs.psamplefile.files.length !== 0) {
        this.mydocloading = true;
        this.saveDisable = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append("file", this.$refs.psamplefile.files[i]);
        }

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch("https://upload.myskool.app", requestOptions)
          .then((response) => response.json()) //2
          .then((result) => {
            //this.moreImages.push(result.myresp);

            for (var i = 0, len = result.myresp.length; i < len; ++i) {
              // this.sampleloading = false;
              var fn = result.myresp[i].path;
              // console.log("fn", fn);
              this.attachList.push(fn);
            }
            // console.log("Hello", this.attachList);
            this.myObj.attachments = this.attachList.join(",");
            this.mydocloading = false;
            this.saveDisable = false;
          })

          .catch((error) => console.log(error));
      }
    },
    filterInquiry() {
      console.log(this.searchDate);
    },
    EditDep(row) {
      // console.log("myRow",row);
      this.depObj.id = row.id;
      this.depObj.name = row.name;
      this.depObj.campusID = this.$store.state.userData.cId;
      this.visibility1 = true;
      this.sidebarTitle = "Edit Department";
      this.sidebarButton = "Update";
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },
    OpenDepartmentF() {
      this.visibility1 = true;
      this.depObj.id = 0;
      this.depObj.name = "";
      this.depObj.campusID = this.$store.state.userData.cId;
      this.sidebarTitle = "Add Department";
      this.sidebarButton = "Save";
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },
    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "StudentInquiries/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.token,
      };
      this.myObj = await this.get(obj);
      console.log("editObj:", this.myObj);
      if (this.myObj.attachments !== null && this.myObj.attachments !== "") {
        this.attachList = this.myObj.attachments.split(",");
        // console.log(this.attachList);
      } else this.attachList = [];

      // var elem = this.$refs["name"];
      // elem.state = undefined;
      // var elem = this.$refs["fname"];
      // elem.state = undefined;
      // this.contacterror = false;
      // var elem = this.$refs["email"];
      // elem.state = undefined;
      // var elem = this.$refs["purpose"];
      // elem.state = undefined;
      // var elem = this.$refs["tag"];
      // elem.state = undefined;
    },

    async loadChecklist() {
      this.allChecklist = await this.get({
        url:
          this.$store.state.domain +
          "InquiryChecklist?db=" +
          this.db +
          "&cID=" +
          this.cID,
        token: this.token,
      });
      // console.log(this.allChecklist);
      this.formattedCL = this.allChecklist.map((item) => ({
        id: 0,
        inquiryID: 0,
        campusID: this.cID,
        checklistID: item.id,
        checkListData: false,
        title: item.title,
      }));
    },
    AddOpen() {
      this.accIcon = "PlusIcon";
      this.checkObj = {
        id: 0,
        title: "",
        details: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility = true;
      var elem = this.$refs["title"];
      elem.state = undefined;
    },
    async editCL(item) {
      this.checkObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["title"];
      elem.state = undefined;
      // console.log(this.checkObj);
    },

    async deleteCL(item) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "InquiryChecklist/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Checklist deleted successfully.",
          context: this,
          token: this.token,
        });
        if (status) this.loadChecklist(1);
      }
    },

    CheckMobile(s, e) {
      if (e.valid) {
        this.contacterror = false;
        this.myObj.contact = e.number;
        // console.log(this.myObj.contact);
      } else {
        this.contacterror = true;
      }
    },
    checkContact() {
      if (this.myObj.contact == "") {
        this.contacterror = true;
      }
    },
    CheckEmail() {
      var elem = this.$refs["email"];
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        re.test(String(this.myObj.email).toLowerCase()) &&
        this.myObj.email !== ""
      ) {
        return (elem.state = true);
      } else {
        return (elem.state = false);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.father_name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkClass() {
      var elem = this.$refs["class"];
      if (this.myObj.cls == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkCampus() {
      var elem = this.$refs["camp"];
      if (this.currentCampus == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkLast() {
      var elem = this.$refs["last_school"];
      if (this.myObj.lastSchool == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkRef() {
      var elem = this.$refs["refer"];
      if (this.myObj.referenceID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPurpose() {
      var elem = this.$refs["purpose"];
      if (this.myObj.purpose == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTag() {
      var elem = this.$refs["tag"];
      if (this.myObj.tag == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
      //  this.$refs.mysidebar.hide();
      //this.$bvModal.hide("modal-login");
    },
    hideDepSideBar() {
      this.visibility1 = false;
      //  this.$refs.mysidebar.hide();
      //this.$bvModal.hide("modal-login");
    },
    Form() {
      this.$router.push("/apps/schoolinfo");
    },
    checkTitle() {
      var elem = this.$refs["title"];
      if (this.checkObj.title === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "studentinquiries/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Inquiry deleted successfully.",
          context: this,
          body: null,
          token: this.token,
        });
        if (status) this.LoadData();
      }
    },

    async Add() {
      // let data = {
      //   inquiry: this.myObj,
      //   checklist: this.formattedCL,
      //   followups: this.followObj,
      // };
      // console.log(data);
      // return true;
      this.CheckName();
      this.CheckFName();
      this.checkLast();
      this.checkClass();
      // this.checkRef();
      this.checkContact();
      this.checkCampus();
      // this.CheckEmail();
      // this.CheckPurpose();
      // this.CheckTag();

      if (
        this.checkCampus() == false ||
        this.CheckName() == false ||
        this.myObj.contact == "" ||
        this.contacterror ||
        this.CheckFName() == false ||
        this.checkLast() == false ||
        this.checkClass() == false
        // ||
        // this.checkRef() == false
        // this.CheckValues() == false
        // this.CheckEmail() == false ||
        // this.CheckPurpose() == false ||
        // this.CheckTag() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
        this.clikced = 1;
      } else {
        this.request = true;
        this.myObj.campusID = this.currentCampus;
        this.myObj.notification = this.notification;
        let data = {
          inquiry: this.myObj,
          checklist: [],
          followups: [],
        };
        console.log(data);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "studentinquiries/Save?db=" +
            this.db +
            "&cID=" +
            this.currentCampus +
            "&sms=" +
            this.notification,
          body: data,
          message: "Form submitted successfully.",
          context: this,
          token: this.token,
        });
        this.request = false;
        //this.visibility1 = false;
        if (status) {
          // let result = await Swal.fire({
          //   title: "Thank you for submitting the form!",
          //   text: "We appreciate your interest and will get back to you as soon as possible.",
          //   icon: "success",
          // });
          if (
            this.$route.query.reg &&
            ["true", true].includes(this.$route.query.reg)
          ) {
            this.$router.replace({
              name: "registration",
              query: { db: "/r6Q0RYpjkcmj801xyc19w==" },
            });
          } else {
            this.submitted = true;
          }
          this.clikced = 1;
        }
      }
    },
    cleanStates() {
      this.submitted = false;
      this.myObj = {
        id: 0,
        name: "",
        father_name: "",
        contact: "",
        age: "",
        email: "",
        address: "",
        purpose: "",
        tag: "",
        date: new Date().toJSON(),
        attachments: "",
        campusID: this.currentCampus,
        cls: 0,
        cnic: "",
        lastSchool: "",
        nextFollowup: new Date().toJSON(),
        inquiryType: "inquiry",
        referenceID: 0,
        inquiryStatus: "pending",
        residentialArea: "",
      };
      setTimeout(() => {
        var elem = this.$refs["name"];
        elem.state = undefined;
        var elem = this.$refs["fname"];
        elem.state = undefined;
        var elem = this.$refs["last_school"];
        elem.state = undefined;
        var elem = this.$refs["class"];
        elem.state = undefined;
        var elem = this.$refs["camp"];
        elem.state = undefined;
        this.contacterror = false;
      }, 300);
    },

    async LoadData() {
      let page = this.currentPage;
      this.byFilter = false;
      this.dataLoading = true;
      this.items = [];
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "studentinquiries?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.token,
      });
      // console.log(myitem);
      this.items = myitem;

      // myitem.forEach((elem) => {
      //   var d = new Date(elem.date);
      //   elem.date = d.toDateString();
      //   //  elem.date =  elem.date.split("T")[0];
      //   this.items.push(elem);
      // });
      // if (this.items.length != 0) {
      //   this.totalRows = this.items.length;
      // }
      this.dataLoading = false;
      setTimeout(() => {
        this.currentPage = page;
      }, 100);
    },
    async LoadFilter() {
      this.byFilter = true;
      this.dataLoading = true;
      var status = await this.post({
        url: this.$store.state.domain + "studentinquiries/Filter",
        body: this.filter,
        subjects: true,
        context: this,
        token: this.token,
      });
      // this.request = false;
      this.$bvModal.hide("modal-login");
      // console.log(status);
      this.items = status;
      this.dataLoading = false;
    },
    async FilterInit() {
      this.filterLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StudentInquiries/FilterInit?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.token,
      };
      //  this.items =[];
      let res = await this.get(obj);
      // console.log(res);
      this.filterTags = res.tags;
      this.filterClasses = res.cls;
      this.filterLoading = false;
      this.$bvModal.show("modal-login");
    },
    setData() {
      // console.log(this.currentCampus);
      this.myObj.cls = 0;
      this.LoadClasses();
      this.checkCampus();
      let obj = this.campuses.find((el) => el.id == this.currentCampus);
      if (obj) {
        this.school.address = obj.address;
        this.school.phone = obj.phone;
      }
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.db +
          "&cId=" +
          this.cID,
        token: this.token,
      };
      this.campuses = await this.get(obj);
      this.currentCampus = this.cID;
      this.setData();
    },
    async LoadClasses() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.db +
          "&cId=" +
          this.currentCampus +
          "&dID=0",
        token: this.token,
      };
      //  this.items =[];
      let myItems = await this.get(obj);
      this.classes = myItems.map((el) => el.cls);
      // this.classes.unshift({ name: "None", id: 0 });
      // console.log(this.classes);
      this.dataLoading = false;
    },
    async loadRefs() {
      this.referenceData = await this.get({
        url:
          this.$store.state.domain +
          "InquiryReferrence?db=" +
          this.db +
          "&cID=" +
          this.cID,
        token: this.token,
      });
    },
    async LoadUsers() {
      this.userLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Users/LoadUsers?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.token,
      };
      this.allUsers = await this.get(obj);
      // console.log(this.allUsers);
      this.userLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.border-dashed {
  border: 1px solid var(--primary);
  border-style: dashed;
}
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
